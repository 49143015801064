<template>
  <v-container fluid>
    <v-toolbar flat color="white">
      <v-toolbar-title class="green--text">{{ $t( "listing.title" ) }}</v-toolbar-title>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-spacer></v-spacer>
      <v-menu
        bottom
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            color="success"
            dark
            v-bind="attrs"
            v-on="on"
          >
            Listing Actions
          </v-btn>
        </template>

        <v-list>
          <v-list-item link @click="newItem">
            <v-list-item-icon>
              <v-icon color="primary">add_box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('listing.btn_new') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
          <v-list-item link @click="importListings">
            <v-list-item-icon>
              <v-icon color="primary">library_add</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('reservation.btn_import') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>         
        </v-list>
      </v-menu>
      <v-divider
        class="mx-2"
        inset
        vertical
      ></v-divider>

      <v-text-field
        v-model="search"
        append-icon="search"
        :label="$t('listing.table_search')"
        single-line
        hide-details
      ></v-text-field>

      <v-dialog v-model="dialog" max-width="1000px" persistent>
        <v-card>
          <v-card-title>
            <span class="headline">{{ formTitle }}</span>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs4 sm4 md4 lg4>
                  <v-text-field v-model="editedItem.listingName" :label="$t('listing.table_listing_name')" :disabled="editedItem.pmsUserId != null && editedItem.pmsUserId.length > 0"></v-text-field>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-text-field v-model="editedItem.internalName" :label="$t('listing.table_listing_name')" :disabled="editedItem.pmsUserId != null && editedItem.pmsUserId.length > 0"></v-text-field>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-text-field v-model="editedItem.guideUrl" :label="$t('listing.table_guide')"></v-text-field>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-text-field v-model="editedItem.pmsUserId" :label="$t('listing.external_account_id')" disabled></v-text-field>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-text-field v-model="editedItem.pmsListingId" :label="$t('listing.external_property_id')" disabled></v-text-field>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-text-field v-model="editedItem.registeredOn" :label="$t('listing.external_property_id')" disabled></v-text-field>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-time-picker
                    v-model="editedItem.checkInTime"
                    color="warning"
                    :allowed-minutes="allowedMinutes"
                    ampm-in-title
                  ></v-time-picker>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-time-picker
                    v-model="editedItem.checkOutTime"
                    color="error"
                    :allowed-minutes="allowedMinutes"
                    ampm-in-title
                  ></v-time-picker>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-select
                    v-model="editedItem.timezone"
                    :items="timezoneList"
                    label="Timezone"
                    item-text="name"
                    item-value="name"
                  ></v-select> 
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-checkbox
                    v-model="editedItem.needManualCheck"
                    :label="$t('listing.table_manual_check')"
                  ></v-checkbox>
                </v-flex>
                <v-flex xs4 sm4 md4 lg4>
                  <v-switch
                    v-model="editedItem.active"
                    :label="$t('listing.table_activated')"
                  ></v-switch>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green lighten-1" text dark @click="close">{{ $t('listing.btn_cancel') }}</v-btn>
            <v-btn color="green lighten-1" text dark @click="save">{{ $t('listing.btn_save') }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>

      <v-dialog v-model="dialogLocks" max-width="800px" persistent>
        <v-card>

          <v-card-title>
            <span class="headline">Fixed Locks</span>

            <v-spacer></v-spacer>

            <v-btn icon @click="addLock">
              <v-icon large>mdi-key-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12 lg12>
                  <v-data-table
                    v-if="editedItem.staticKeyItems && editedItem.staticKeyItems.length > 0"
                    :headers="headersFixedLocks"
                    :items="editedItem.staticKeyItems"
                    class="elevation-1"
                    hide-default-footer
                  > 
                    <template v-slot:item.delete="{ index, item }">
                      <v-icon
                        small
                        class="mr-2"
                        color="green lighten-1"
                        @click="removeStaticKeyItem(index)"
                      >
                        delete
                      </v-icon>
                    </template>
                    <template v-slot:item.roomCode="{ index, item }">
                      <v-text-field v-model="editedItem.staticKeyItems[index].roomCode" :hide-details="true" dense single-line :autofocus="true"></v-text-field>
                    </template>

                    <template v-slot:item.keyInfo="{ index, item }">
                      <v-text-field v-model="editedItem.staticKeyItems[index].keyInfo" :hide-details="true" dense single-line :autofocus="true"></v-text-field>
                    </template>

                  </v-data-table>

                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-title>
            <span class="headline">Smart Locks</span>

            <v-spacer></v-spacer>

            <v-btn icon @click="addSmartLock">
              <v-icon large>mdi-key-plus</v-icon>
            </v-btn>
          </v-card-title>

          <v-card-text>
            <v-container grid-list-md>
              <v-layout wrap>
                <v-flex xs12 sm12 md12 lg12>
                  <v-data-table
                    v-if="editedItem.smartKeyItems && editedItem.smartKeyItems.length > 0"
                    :headers="headersLocks"
                    :items="editedItem.smartKeyItems"
                    class="elevation-1"
                    hide-default-footer
                  > 
                    <template v-slot:item.delete="{ index, item }">
                      <v-icon
                        small
                        class="mr-2"
                        color="green lighten-1"
                        @click="removeSmartKeyItem(index, item)"
                      >
                        delete
                      </v-icon>
                    </template>

                    <template v-slot:item.equipmentName="{ index, item }">
                      <v-select
                        v-model="editedItem.smartKeyItems[index]"
                        :items="keypads"
                        item-text="equipmentName"
                        return-object
                      ></v-select>
                    </template>

                  </v-data-table>

                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="green lighten-1" text dark @click="close">{{ $t('listing.btn_cancel') }}</v-btn>
            <v-btn color="green lighten-1" text dark @click="save">{{ $t('listing.btn_save') }}</v-btn>
          </v-card-actions>

        </v-card>
      </v-dialog>
    </v-toolbar>



    <v-data-table
      :headers="headers"
      :items="listings"
      :search="search"
      class="elevation-1"
      :items-per-page="5"
    >

      <template v-slot:item.pic="{ item }">
        <v-img :src="item.picture" style="width: 80px;"></v-img>
      </template>

    


      <template v-slot:item.edit="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="editItem(item)"
        >
          edit
        </v-icon>
      </template>

      <template v-slot:item.locks="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="pickListingForLocks(item)"
        >
          lock
        </v-icon>
      </template>


      <template v-slot:item.import="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="importReservations(item)"
        >
          event_repeat
        </v-icon>
      </template>

      <template v-slot:item.resevations="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="pickListingForReservations(item)"
        >
          date_range
        </v-icon>
      </template>

      <template v-slot:item.cameras="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="pickListingForCameras(item)"
        >
          camera_outdoor
        </v-icon>
      </template> 

      <template v-slot:item.cameraRecords="{ item }">
        <v-icon
          large
          class="mr-2"
          color="green lighten-1"
          @click="pickListingForCameraRecords(item)"
        >
          video_file
        </v-icon>
      </template> 
    </v-data-table>
     <div style="width: 100%; height: 800px" v-if="!dialog && !dialogLocks">
      <d3-geo-leaf-icons
        tile-provider1="Esri.WorldStreetMap"
        tile-provider="Stadia.AlidadeSmooth"
        :geojson-object="geojson"
        color="#FF6600"
        :auto-fit-bounds="true"
        >    
      </d3-geo-leaf-icons>  
    </div>
  </v-container>
</template>

<style scoped>
  table.v-table thead tr th {
    font-size: 28px;
  }
  table.v-table tbody tr td {
    font-size: 20px;
  }
</style>

<script>
import { mapState, mapActions } from 'vuex'  
import { CallDialog } from '@/utils'
import store from '@/store'
import ct from 'countries-and-timezones'

import * as d3geo from 'vue-d3-geo'
import 'vue-d3-geo/dist/vue-d3-geo.css'

export default {
  data: () => ({
    allowedMinutes: (v) => { v == 0 },
    rules: {
      maxLength: value => value.length <= 200 || 'Max 200 characters',
    },
    search: '',
    dialog: false,
    dialogLocks: false,
    editedIndex: -1,
    editedItem: {
      hostId: '',
      listingId: '',
      listingName: '',
      internalName: '',
      needManualCheck: false,
      guideUrl: '',
      registeredOn: '',
      pmsListingId: '',
      pmsUserId: '',
      staticKeyItems: [],
      smartKeyItems: [],
      checkInTime: '12:00',
      checkOutTime: '12:00',
      countryCode: '',
      timezone: '',
      active: false
    },
    defaultItem: {
      hostId: '',
      listingId: '',
      listingName: '',
      internalName: '',
      needManualCheck: false,
      guideUrl: '',
      registeredOn: '',
      pmsListingId: '',
      pmsUserId: '',
      staticKeyItems: [],
      smartKeyItems: [],
      checkInTime: '12:00',
      checkOutTime: '12:00',
      countryCode: '',
      timezone: '',
      active: false
    },
    pagination: {
      descending: true,
      page: 1,
      rowsPerPage: 25,
      sortBy: 'registeredOn',
      totalItems: 0,
      rowsPerPageItems: [10,25,{"text":"$vuetify.dataIterator.rowsPerPageAll","value":-1}]
    },
  }),
  computed: {
    geojson() {
      const features = this.listings.map(listing => {
        return {
          type: "Feature",
          properties: { 
            scannerName: listing.listingName
          },
          geometry: {
            type: "Point",
            coordinates: [
              listing.address.longitude,
              listing.address.latitude
            ]
          }
        }
      })

      const result = {
        type: "FeatureCollection",
        features: features
      }

      return result
    },
    formTitle () {
      return this.editedIndex === -1 ? this.$t('listing.btn_new') : this.$t('listing.btn_edit')
    },
    disabled () {
      return this.editedIndex === -1 ? false : true
    },
    headers () {
      return [
        { text: this.$t('listing.table_pic'), value: 'pic', align: 'left', sortable: false },
        { text: this.$t('listing.table_edit'), value: 'edit', align: 'left', sortable: false },
        { text: this.$t('listing.table_locks'), value: 'locks', align: 'left', sortable: false },
        { text: this.$t('listing.table_import_reservations'), value: 'import', align: 'left', sortable: false },
        { text: this.$t('listing.table_goto_reservations'), value: 'resevations', align: 'left', sortable: false },
        { text: this.$t('listing.table_listing_name'), value: 'listingName', align: 'left', sortable: false},
        { text: this.$t('listing.table_internal_name'), value: 'internalName', align: 'left', sortable: true},
        { text: this.$t('listing.table_listing_id'), value: 'listingId', align: 'left', sortable: false},
        { text: this.$t('listing.table_manual_check'), value: 'needManualCheck', align: 'left', sortable: false},
        { text: this.$t('listing.table_guide'), value: 'guideUrl', align: 'left', sortable: false},
        { text: this.$t('listing.table_registerd_on'), value: 'registeredOn', align: 'left', sortable: true},
        { text: this.$t('listing.table_goto_camers'), value: 'cameras', align: 'left', sortable: false },
        { text: this.$t('listing.table_goto_camera_records'), value: 'cameraRecords', align: 'left', sortable: false },
      ]
    },
    headersLocks () {
      return [
        { text: this.$t('listing.table_edit'), value: 'delete', align: 'left', sortable: false },
        { text: this.$t('listing.table_device_name'), value: 'equipmentName', align: 'left', sortable: true},
        { text: this.$t('listing.table_room_code'), value: 'roomCode', align: 'left', sortable: true },
      ]
    },
    headersFixedLocks () {
      return [
        { text: this.$t('listing.table_edit'), value: 'delete', align: 'left', sortable: false },
        { text: this.$t('listing.table_room_code'), value: 'roomCode', align: 'left', sortable: true },
        { text: this.$t('listing.table_key_info'), value: 'keyInfo', align: 'left', sortable: true},
      ]
    },
    timezoneList () {
      // console.log('ct.getAllTimezones()', ct.getAllTimezones())
      if (this.editedItem.countryCode) {
        return ct.getTimezonesForCountry(this.editedItem.countryCode)
      } else {
        return Object.values(ct.getAllTimezones())
      }
    },
    ...mapState({
      listings: state => state.host.listings,
      // listing: state => state.host.listing,
      keypads: state => state.host.keypads
    }),
  },
  async beforeRouteEnter (to, from, next) {
    const error = await store.dispatch('host/loadListings').catch(error => {
      return error
    })

    if (error) {
      CallDialog(error)
    } else {
      next()
    }
  },
  methods: {
    newItem () {
      this.editedIndex = -1;
      this.pickListing(Object.assign({}, this.defaultItem))
      this.dialog = true
    },
    async editItem (item) {
      this.pickListing(item)

      this.editedIndex = this.listings.indexOf(item)
      this.editedItem = Object.assign({}, this.listings[this.editedIndex])

      if (!this.listings[this.editedIndex].staticKeyItems) {
        this.editedItem.staticKeyItems = []  
      } else {
        this.editedItem.staticKeyItems = [...this.listings[this.editedIndex].staticKeyItems]  
      }
      
      if (!this.listings[this.editedIndex].smartKeyItems) {
        this.editedItem.smartKeyItems = []  
      } else {
        this.editedItem.smartKeyItems = [...this.listings[this.editedIndex].smartKeyItems]  
      }

      this.dialog = true
    },
    async pickListingForLocks (item) {

      this.pickListing(item)

      await this.loadListingLocks()

      this.editedIndex = this.listings.indexOf(item)
      this.editedItem = Object.assign({}, this.listings[this.editedIndex])

      if (!this.listings[this.editedIndex].staticKeyItems) {
        this.editedItem.staticKeyItems = []  
      } else {
        this.editedItem.staticKeyItems = [...this.listings[this.editedIndex].staticKeyItems]  
      }
      
      if (!this.listings[this.editedIndex].smartKeyItems) {
        this.editedItem.smartKeyItems = []  
      } else {
        this.editedItem.smartKeyItems = [...this.listings[this.editedIndex].smartKeyItems]  
      }

      this.dialogLocks = true
    },
    removeSmartKeyItem (index, item) {
      confirm('Are you sure you want to delete this item?') && this.editedItem.smartKeyItems.splice(index, 1)
    },
    removeStaticKeyItem (index) {
      confirm('Are you sure you want to delete this item?') && this.editedItem.staticKeyItems.splice(index, 1)
    },
    addSmartLock () {
      console.log('addSmartLock before', this.editedItem.smartKeyItems)

      if (!this.editedItem.smartKeyItems) {
        this.editedItem.smartKeyItems = [];
      }

      this.editedItem.smartKeyItems.push({
        roomCode: '',
        equipmentId: ''
      })

      console.log('addSmartLock after', this.editedItem.smartKeyItems)
    },    
    addLock () {
      console.log('addLock before', this.editedItem.staticKeyItems)

      if (!this.editedItem.staticKeyItems) {
        this.editedItem.staticKeyItems = [];
      }

      this.editedItem.staticKeyItems.push({
        roomCode: '',
        keyInfo: ''
      })

      console.log('addLock after', this.editedItem.staticKeyItems)
    },
    async save () {
      console.log('save', this.editedIndex)
      if (this.editedIndex > -1) {
        const error = await this.updateListing({
          params: this.editedItem,
          index: this.editedIndex
        }).catch(e => {
          console.error('updateListing', e)
          return e
        })

        if (error) {
          CallDialog(error)  
        } else {
          this.close()
        }
      } else {
        console.log('this.editedItem', this.editedItem)
        this.editedItem.registeredOn = ((new Date).toISOString()).substring(0,10)
        const error = await this.createListing(this.editedItem).catch(e => {
          console.error('createListing', e)
          return e
        })

        if (error) {
          CallDialog(error)  
        } else {
          this.close()
        }
      }
    },
    close () {
      this.dialog = false
      this.dialogLocks = false
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      }, 10)
    },
    pickListingForReservations (val) {
      this.gotoReservations(val)
    },
    pickListingForScanners (val) {
      this.pickListing(val)

      this.gotoScanners(val)
    },
    pickListingForCameras (val) {
      this.pickListing(val)

      this.gotoCameras(val)
    },
    pickListingForCameraRecords (val) {
      this.pickListing(val)

      this.gotoCameraRecords(val)
    },
    ...mapActions({
      loadListings: 'host/loadListings',
      updateListing: 'host/updateListing',
      createListing: 'host/createListing',
      importListings: 'host/importListings',
      importReservations: 'host/importReservations',
      gotoReservations: 'host/gotoReservations',
      gotoScanners: 'scanner/gotoScanners',
      gotoCameras: 'scanner/gotoCameras',
      gotoCameraRecords: 'scanner/gotoCameraRecords',
      pickListing: 'host/pickListing',
      loadListingLocks: 'host/loadListingLocks'
    })
  },
  components: {
    D3GeoLeafIcons: d3geo.D3GeoLeafIcons,
  }
}
</script>